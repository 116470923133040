import { ISelection } from "../Components/selector.compoenent";
import { IExportedSelection, IFileTemplate, Rectangle } from "../model/IExportedSelection";
import { FieldType, FieldFormat } from "../model/applicationState";
import UTIF from "utif";
import { useState, useEffect } from 'react';

export function isPdfFileExtension(fileName: string) {
    let extension = fileName.split('.').pop() || '';
    return extension.trim().toUpperCase() === 'PDF';
}

export function buildFileTemplateForExport(selections: ISelection[], fileName: string, isPdfFile: boolean, scale: string): string {
    let exportedContent: IFileTemplate = {
        scaleValue: scale,
        fileName: fileName,
        isPdfTemplate: isPdfFile,
        selections: selections.map((selection, index) => {
            return <IExportedSelection>{
                id: selection.id,
                name: selection.name || `Rule #${index}`,
                page: selection.page,
                rawRectangle: <Rectangle>{
                    left: selection.left,
                    top: selection.top,
                    width: selection.width,
                    height: selection.height,
                },
                pdfRectangle: selection.pdfRectangle
            }
        })
    };

    return JSON.stringify(exportedContent);
}

// convert degree to radians
export function degreeToRadians(degree: number) {
    return degree * Math.PI * 2 / 360;
}

export async function loadImageToCanvas(imageUrl: string): Promise<HTMLCanvasElement> {
    return new Promise((resolve, reject) => {
        const img: HTMLImageElement = document.createElement("img");
        img.onload = async () => {
            const rotatedImage = renderRotatedImageToCanvas(img, 0);
            resolve(rotatedImage);
        };
        img.crossOrigin = "anonymous";
        img.onerror = reject;
        img.src = imageUrl;
    });
}

export function parseTiffData(tiffData: ArrayBuffer): any[] {
    const tiffImages = UTIF.decode(tiffData);
    for (const tiffImage of tiffImages) {
        UTIF.decodeImage(tiffData, tiffImage);
    }

    return tiffImages;
}

export function renderTiffToCanvas(tiffImage): HTMLCanvasElement {
    const rgbData = new Uint8ClampedArray(UTIF.toRGBA8(tiffImage).buffer);
    const imageData = new ImageData(rgbData, tiffImage.width, tiffImage.height);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    canvas.height = tiffImage.height;
    canvas.width = tiffImage.width;
    context.putImageData(imageData, 0, 0);

    return canvas;
}

export function renderRotatedImageToCanvas(image: HTMLImageElement, orientation: number): HTMLCanvasElement {
    const width = image.width;
    const height = image.height;

    const canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    if (orientation > 4) {
        canvas.width = height;
        canvas.height = width;
    }

    const ctx = canvas.getContext("2d");
    switch (orientation) {
        case 2:
            // horizontal flip
            ctx.translate(width, 0);
            ctx.scale(-1, 1);
            break;

        case 3:
            // 180° rotate left
            ctx.translate(width, height);
            ctx.rotate(Math.PI);
            break;

        case 4:
            // vertical flip
            ctx.translate(0, height);
            ctx.scale(1, -1);
            break;

        case 5:
            // vertical flip + 90 rotate right
            ctx.rotate(0.5 * Math.PI);
            ctx.scale(1, -1);
            break;

        case 6:
            // 90° rotate right
            ctx.rotate(0.5 * Math.PI);
            ctx.translate(0, -height);
            break;

        case 7:
            // horizontal flip + 90 rotate right
            ctx.rotate(0.5 * Math.PI);
            ctx.translate(width, -height);
            ctx.scale(-1, 1);
            break;

        case 8:
            // 90° rotate left
            ctx.rotate(-0.5 * Math.PI);
            ctx.translate(-width, 0);
            break;
    }
    ctx.drawImage(image, 0, 0, width, height);
    return canvas;
}

/**
 * Filters tag's format according to chosen tag's type
 * @param FieldType The json object
 * @returns [] of corresponding tag's formats
 */
export function filterFormat(type: FieldType | string): any[] {
    switch (type) {
        case FieldType.String:
            return [
                FieldFormat.NotSpecified,
                FieldFormat.Alphanumeric,
                FieldFormat.NoWhiteSpaces,
            ];
        case FieldType.Number:
            return [
                FieldFormat.NotSpecified,
                FieldFormat.Currency,
            ];
        case FieldType.Date:
            return [
                FieldFormat.NotSpecified,
                FieldFormat.DMY,
                FieldFormat.MDY,
                FieldFormat.YMD,
            ];
        case FieldType.Object:
        case FieldType.Array:
            return [
                FieldFormat.NotSpecified,
            ];
        default:
            return [FieldFormat.NotSpecified];
    }
}

/**
 * UseDebounce - custom React hook for handling fast changing values, the hook re-call only if value or delay changes
 * @param value The value to be changed
 * @param delay - delay after which the change will be registered in milliseconds
 */
export function useDebounce(value: any, delay: number) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const delayHandler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // cleanup
            return () => {
                clearTimeout(delayHandler);
            };
        },
        [value, delay]
    );
    return debouncedValue;
}

export function getTagCategory(tagType: string) {
    switch (tagType) {
        case FieldType.SelectionMark:
        case "checkbox":
            return "checkbox";
        case FieldType.Object:
            return FieldType.Object;
        case FieldType.Array:
            return FieldType.Array;
        default:
            return "text";
    }
}

/**
 * @param {Object} object
 * @param {string} key
 * @return {any} value
 */
export function getParameterCaseInsensitive(object: any, key: string) {
    return object[Object.keys(object)
        .find(k => k.toLowerCase() === key.toLowerCase())
        ];
}
