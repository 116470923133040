import './App.scss';
import './index.scss'
import Header from "./Header";
import { useEffect, useState } from "react";
import { CefContent, getBlobData } from "./CefContent";
import { ErrorDialog } from "./Components/ErrorDialog";
import { isPdfFileExtension } from "./Utils/utils";
import EditorPage from "./pages/predict/EditorPage";
import {IDocumentResults, ILabelData, ITag} from './model/applicationState';
import { KeyboardManager } from "./common/keyboardManager/keyboardManager";
import clone from "rfdc";

import microsoft_response_data from "./analyze_result.json";
import tagsData from "./tags.json"
import { constants } from './common/constants';
import axios from 'axios';

declare var CefSharp: any;
declare var boundAsync: any;

function App() {

  const [predictionResult, setPredictionResult] = useState<any>();
  const [initialPredictionResult, setInitialPredictionResult] = useState<any>();
  const [docType, setDocType] = useState<string>();
  const [modelId, setModelId] = useState<string>();
  const [documentFileName, setDocumentFileName] = useState<any>('default');

  const [documentLoaded, setDocumentLoaded] = useState<boolean>();
  const [isPDfFile, setIsPDfFile] = useState<boolean>();
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>();
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tagsSet, setTagsSet] = useState<ITag[]>();
  const [contentByPage, setContentByPage] = useState({});
  const [hasChanges, setHasChanges] = useState<boolean>();
  const [regionsChanged, setRegionsChanged] = useState<boolean>();
  const [validated, setValidated] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const host_url = "https://mlapi.rinkt.com"
  // const host_url = "http://localhost:8000"
  const params = new URLSearchParams(window.location.search);
  const hasValidOfflineParams = ["mid", "pid", "uid", "rid", "file_url"]
                                  .map((p) => params.get(p) !== null && params.get(p) !== '')
                                  .filter((el) => el == false)
                                  .length == 0

  const resetState = () => {
    setPredictionResult(undefined);
    setInitialPredictionResult(undefined);
    setDocType(undefined);
    setModelId(undefined);
    setDocumentFileName('default');
    setDocumentLoaded(false);
    setIsPDfFile(false);

    setFile(undefined);
    setFileUrl(undefined);
    setShowError(false);
    setErrorMessage('');
    setTagsSet(undefined);
    setContentByPage({});
    setHasChanges(false);
    setRegionsChanged(false);
  }

  const initializeFieldsData = (documentResults: IDocumentResults) => {
    let fieldsData = documentResults.fields
    for (let field in fieldsData) {
      const data = fieldsData[field];
      if ('valueArray' in data) {
        data['valueArray'].map((el: any, ix: number) => { return el.id = ix })
      }
    }
  }

  const initializePredictionResult = (predictionResults) => {
    if (constants.tagsValidatedByDefault) {
      let newPredictionResults = clone()(predictionResults);
      const documentResults: IDocumentResults = predictionResults.analyzeResult.documentResults[0];
      for (const [_, field] of Object.entries(documentResults.fields)) {
        field.validated = true;
      }
      setPredictionResult(newPredictionResults);
    } else {
      setPredictionResult(predictionResults);
    }
  }

  const initializeDocType = (documentResults: IDocumentResults) => {
    setDocType(documentResults.docType)
  }

  const initializeModelId = (documentResults: IDocumentResults) => {
    setModelId(documentResults.modelId)
  }

  const setData = async(data: any) => {
    const documentResults: any = data.prediction['analyzeResult']['documentResults'][0];

    initializeFieldsData(documentResults);
    initializePredictionResult(data.prediction);
    initializeDocType(documentResults)
    initializeModelId(documentResults)
    if (data.tags) {
      setTagsSet(data.tags)
    }
    setIsPDfFile(isPdfFileExtension(data.file.filename));
    setDocumentLoaded(true);

    if(data.file.content){
      const fileType = getFileType(data.file.filename);
      const file = new File([data.file.content], data.file.filename, { type: fileType });
      setFile(file);
      setFileUrl(URL.createObjectURL(file));
    }
  }

  useEffect(() => {
    async function getData() {
      if (typeof CefSharp !== 'undefined' && CefSharp && CefSharp.BindObjectAsync) {
        await CefSharp.BindObjectAsync('boundAsync');

        resetState();

        const cefContentString = await boundAsync.getDocumentContent() as string;
        const cefContent = JSON.parse(cefContentString) as CefContent;
        let allData = JSON.parse(cefContent.JsonResponseContent);
        const contentByPage = cefContent.JsonResponseContentByPage && JSON.parse(cefContent.JsonResponseContentByPage);
        allData = mergeResults(allData, contentByPage);
        if (contentByPage) {// if we have multiple responses, one per page, save it
          setContentByPage(contentByPage);
          setInitialPredictionResult(JSON.parse(cefContent.JsonResponseContent));
        }

        setData({
          "file": {
            "filename": cefContent.DocumentFileName,
            "content": await getBlobData(cefContent.DocumentFileContent)
          },
          "tags": cefContent.tags,
          "prediction": allData
        })
      }
    }


    const assignData = async (js: any) => {
      if (js.validated == true){
        setValidated(true)
      }
      const content = await getBlobData(js.file.content)
      setData({
          "file": {
            "filename": js.file.filename,
            "content": content 
          },
          "tags": js.tags,
          "prediction": js.prediction
        })
    }
    if (hasValidOfflineParams){
      axios.get(`${host_url}/operator_prediction_result${window.location.search}`)
        .then((r) => {
          return r.data
        })
        .then(assignData)
        .catch(_ => {
            setNotFound(true);
            console.log("setting not found")
        })
    } else {
      getData();
    }

  }, []);

  // if (notFound) {
  // return notFound && (<div className="bg-white h-100 text-center display-1 py-5 text-black">This document doesn't exist or was already validated</div>)
  // }

  function mergeResults(initialAnalyzeResult: any, analyseResultByPage: any) {
    if (!analyseResultByPage && !initialAnalyzeResult.merged) return initialAnalyzeResult;

    const fieldsData = initialAnalyzeResult['analyzeResult']['documentResults'][0]['fields']
    for (let pageNumber in analyseResultByPage) {
      const intPageNumber = parseInt(pageNumber);

      const pageResults = analyseResultByPage[pageNumber]['analyzeResult']['pageResults'];
      const readResults = analyseResultByPage[pageNumber]['analyzeResult']['readResults'];
      if (pageResults && pageResults.length > 0 && initialAnalyzeResult['analyzeResult']['pageResults']) {
        pageResults[0].page = intPageNumber;
        initialAnalyzeResult['analyzeResult']['pageResults'].push(pageResults[0]);
      }
      if (readResults && readResults.length > 0 && initialAnalyzeResult['analyzeResult']['readResults']) {
        readResults[0].page = intPageNumber;
        initialAnalyzeResult['analyzeResult']['readResults'].push(readResults[0]);
      }

      let pageFields = analyseResultByPage[pageNumber]['analyzeResult']['documentResults'][0]['fields'];
      for (let field in fieldsData) {
        const data = fieldsData[field];
        const pageData = pageFields[field];
        if ('valueArray' in data || (data && data.type === 'array')) {
          if ('valueArray' in pageData) {
            for (let i = 0; i < pageData['valueArray'].length; i++) {
              const valueObject = pageData['valueArray'][i]['valueObject'];
              pageData['valueArray'][i].page = pageNumber;//added to mark the page
              for (let objkey in valueObject) {
                valueObject[objkey].page = intPageNumber;
                if(valueObject[objkey].elements){
                  valueObject[objkey].elements = valueObject[objkey].elements.map(e => e.replace('/readResults/0', `/readResults/${(intPageNumber - 1).toString()}`))
                }
              }
            }
            data['valueArray'] = (data['valueArray'] || []).concat(pageData['valueArray']);
          }
        } else if (pageData && pageData.text && pageData.text !== '' && (!data || (!data.text && !data.fieldChanged) || (data.confidence < pageData.confidence && !data.fieldChanged) )) {
          fieldsData[field] = pageData;
          pageData.page = intPageNumber;
          if(pageData.elements){
            pageData.elements = pageData.elements.map(e => e.replace('/readResults/0', `/readResults/${(intPageNumber - 1).toString()}`))
          }
        }
      }
    }
    return initialAnalyzeResult;
  }

  function getFileType(fileName: string) {
    const splitName = fileName.split(".");
    let fileExtension = splitName[splitName.length - 1];
    if (fileExtension) {
      fileExtension = fileExtension.toLowerCase();
    }
    switch (fileExtension) {
      case 'pdf':
        return 'application/pdf';
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'tiff':
        return 'image/tiff';
      default:
        return 'application/pdf';
    }
  }

  const fields = predictionResult && predictionResult['analyzeResult']['documentResults'][0]['fields'];
  if (typeof CefSharp === 'undefined' && fields === undefined && !hasValidOfflineParams) {
    async function setDefault(){
      const filename = "f1.pdf"
      const content = await fetch(filename).then(r => r.blob())
      setData({
        "file": {
          "filename": filename,
          "content": content
        },
        "tags": tagsData,
        "prediction": microsoft_response_data
      })
    }
    setDefault()
  }

  if(notFound){
    return (<div className="bg-white h-100 text-center display-1 py-5 text-black">This document doesn't exist or was already validated</div>)
  } 

  if (fields === undefined) {
    return null;
  }

  const sendMultiPageResult = (result: any) => {

    const analyseResultByPage: any = contentByPage;
    const firstPageFields = initialPredictionResult['analyzeResult']['documentResults'][0]['fields'];
    for (let pageNumber in analyseResultByPage) {
      const intPageNumber = parseInt(pageNumber);

      result['analyzeResult']['pageResults'][intPageNumber - 1].page = 1;
      analyseResultByPage[pageNumber]['analyzeResult']['pageResults'] = [result['analyzeResult']['pageResults'][intPageNumber - 1]];
      result['analyzeResult']['readResults'][intPageNumber - 1].page = 1;
      analyseResultByPage[pageNumber]['analyzeResult']['readResults'] = [result['analyzeResult']['readResults'][intPageNumber - 1]];
      let fieldsData = result['analyzeResult']['documentResults'][0]['fields'];
      const pageFields = analyseResultByPage[pageNumber]['analyzeResult']['documentResults'][0]['fields'];
      for (let field in fieldsData) {
        const data = fieldsData[field];
        if ('valueArray' in data) { // try to copy and remove all elements from other pages than 1 from big result to individual pages results
          const valueObjectsToAdd = [];
          for (let i = 0; i < data['valueArray'].length; i++) {
            const valueObject = data['valueArray'][i]['valueObject'];
            let addValueObject = false;
            for (let objkey in valueObject) {
              if(valueObject[objkey].page === intPageNumber){ // if we have the same page, remove field and move it to
                valueObject[objkey].page = 1;
                if(valueObject[objkey].elements){
                  valueObject[objkey].elements = valueObject[objkey].elements.map(e => e.replace(`/readResults/${(intPageNumber - 1).toString()}`, '/readResults/0'));
                }
                addValueObject = true;
              }
            }
            if (addValueObject){
              valueObjectsToAdd.push(data['valueArray'][i]);
              //delete element from array
              data['valueArray'].splice(i, 1);
              i--;
            }
          }
          //move array to the right result object
          if(pageFields[field] && valueObjectsToAdd.length > 0){
            pageFields[field]['valueArray'] = valueObjectsToAdd;
          }
        } else if(data.page === intPageNumber){
          pageFields[field] = data;
          pageFields[field].page = 1;
          if(pageFields[field].elements){
            pageFields[field].elements = pageFields[field].elements.map(e => e.replace(`/readResults/${(intPageNumber - 1).toString()}`, '/readResults/0'));
          }
          if(firstPageFields.hasOwnProperty(field)){
            fieldsData[field] = firstPageFields[field];
          }
        }
      }
    }
    //leave only the first page result
    result['analyzeResult']['readResults'].splice(1);
    result['analyzeResult']['pageResults'].splice(1);

    boundAsync.submitToVerification(JSON.stringify(result), JSON.stringify(contentByPage), regionsChanged, false);
  }

  const ignoreValidation = () => {
    boundAsync.submitToVerification(null, null, false, true);
  }

  const submitData = (result: any, labelData: ILabelData) => {
    if (typeof boundAsync !== 'undefined') {
      if (Object.entries(contentByPage).length > 0) {
        try {
          sendMultiPageResult(result);
        } catch (e) {
          boundAsync.submitToVerification(null, null, false, false);
        }
      } else {
        boundAsync.submitToVerification(hasChanges ? JSON.stringify(result) : null, null, regionsChanged, false);
      }
    } else {
      if(hasValidOfflineParams){
        try{
          axios
            .post(`${host_url}/operator_prediction_result/${params.get("mid")}/${params.get("rid")}/${params.get("uid")}`, result)
            .then((_) => {
              alert("Successfully validated information");
              try {
                // @grant        window.close
                window.open('', '_self').close();
              } catch{}
            })
              .catch(err => {
                console.error(err);
                alert("Unable to submit result");
              });
        } catch(error){
          console.error(error)
          alert("Unable to submit result");
        }
      }
    }
  }

  const onRegionChanged = () => {
    setRegionsChanged(true);
  }

  const updatePredictionResult = (data, field: any, pageNo: number) => {
    setPredictionResult(data);
    setHasChanges(true);
  }

  return (
    <>
      <KeyboardManager>
        <div className="app-shell">
          <Header docType={docType} modelId={modelId} ignore={ignoreValidation} validated={validated} />
          <div className="app-main bg-dark">
            <div className="app-content bg-dark-light text-white">
              { !notFound && file && <EditorPage
                tags={tagsSet}
                file={file}
                fileUrl={fileUrl}
                predictionResult={predictionResult}
                updatePredictionResult={updatePredictionResult}
                submitValidation={submitData}
                onRegionChanged={onRegionChanged}
              />}
            </div>
            <ErrorDialog open={showError} onClose={() => { setShowError(false) }} message={errorMessage} />
          </div >
        </div>
      </KeyboardManager>
    </>
  );
}

export default App;
