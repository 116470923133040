import logoSmall from "./logoSmall.png";
import React from "react";

export interface IHeaderProps {
  docType: string;
  modelId: string;
  validated: boolean;
  ignore: () => void;
}

export default function Header(props: IHeaderProps) {
  const ignoreContent = '  SKIP  ';
  return (<header className="bg-dark-light">
    {props.validated?(<div className="text-center bg-warning p-3">Document already validated</div>) : (<></>)}
    <div>
      <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" className="d-flex align-items-center mb-lg-0 text-dark text-decoration-none">
          <img src={logoSmall} alt="logo" width="64px" />
          <div className="text-white" style={{ marginLeft: 20, fontWeight: 30 }}>Rinkt Validation Station</div>

          <div className="text-white" style={{ marginLeft: 50 }}>Model Name: {props.docType}</div>
          <div className="text-white" style={{ marginLeft: 20 }}>Model Id: {props.modelId}</div>

          <button className='btn btn-primary' style={{ marginLeft: 50 }} onClick={props.ignore}>
            {ignoreContent}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-skip-forward" viewBox="0 0 16 16">
              <path d="M15.5 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V8.752l-6.267 3.636c-.52.302-1.233-.043-1.233-.696v-2.94l-6.267 3.636C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696L7.5 7.248v-2.94c0-.653.713-.998 1.233-.696L15 7.248V4a.5.5 0 0 1 .5-.5zM1 4.633v6.734L6.804 8 1 4.633zm7.5 0v6.734L14.304 8 8.5 4.633z"></path>
            </svg>
          </button>
        </a>
        {/*<ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          <li><a href="#" className="nav-link px-2 link-secondary">Overview</a></li>
         </ul>*/}
      </div>
    </div>
  </header>)
}
