/**
 * Constants used throughout application
 */

const supportedFieldsSchemas = new Set(["http://www.azure.com/schema/formrecognizer/fields.json", "https://schema.cognitiveservices.azure.com/formrecognizer/2021-03-01/fields.json"]);
const supportedLabelsSchemas = new Set(["http://www.azure.com/schema/formrecognizer/labels.json", "https://schema.cognitiveservices.azure.com/formrecognizer/2021-03-01/labels.json"]);

export const constants = {
    pdfjsWorkerSrc(version: string) {
        return `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.js`;
    },

    pdfjsCMapUrl(version: string) {
        return `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/cmaps/`;
    },
    convertedImageFormat: "image/jpeg",
    convertedImageQuality: 0.7,
    labelsSchema: "https://schema.cognitiveservices.azure.com/formrecognizer/2021-03-01/labels.json",
    supportedFieldsSchemas,
    supportedLabelsSchemas,
    enableMultiPageField: false,
    showOriginLabelsByDefault: true,
    tagsValidatedByDefault: true,
    editModeEnabledByDefault: false,
};
